import { Box, Button, Flex, FormControl, FormLabel, HStack, Input, Select, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { map } from "lodash";
import React, { useEffect, useState } from "react";
import { useZwitchStore } from "../../store/Zwitch";
import { STATUS } from "../../Constant";
import dayjs from "dayjs";
import { MdCurrencyRupee } from "react-icons/md";
import { DownloadExcel } from "../../Constant/DownloadExcel";
import { RiFileExcel2Fill } from "react-icons/ri";
import { ViewIcon } from "@chakra-ui/icons"; // Importing the Eye Icon
import { IconButton } from "@chakra-ui/react";
import DetailModal from "./model";

export const Ledger = () => {
    const [inputValue, setInputValue] = useState({ startDate: dayjs().format("YYYY-MM-DD"), endDate: dayjs().format("YYYY-MM-DD"), type: "All" })
    const [excelData, setExcelData] = useState(null)

    const inputHandler = (name, val) => {
        setInputValue(pre => ({ ...pre, [name]: val }))
    }

    const { transactionAction, transactionStatus, transactions } = useZwitchStore(s => ({
        transactionAction: s.transactionAction,
        transactionStatus: s.transactionStatus,
        transactions: s.transactions
    }))

    useEffect(() => {
        if ((transactionStatus || 1) === STATUS.NOT_STARTED) {
            transactionAction(inputValue)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionAction, transactionStatus])

    const getFilter = () => {
        transactionAction(inputValue)
    }

    useEffect(() => {
        if (transactions) {
            const data = transactions.map(tran => {
                return ({
                    Date: dayjs(tran.date).format("DD-MMM-YYYY hh:mm A"),
                    Amount: tran.amount,
                    Contact: tran.contact.name,
                    Source: tran.type,
                    "Reference Id": tran.referenceId,
                    Via: tran.via,
                    UTR: tran.transId,
                })
            });
            setExcelData(data)
        }
    }, [transactions])

    const [selectedItem, setSelectedItem] = useState(null);
    // eslint-disable-next-line no-undef

    const [isOpen, setIsOpen] = useState(false);

    const handleShowDetails = (item) => {
        setSelectedItem(item);
        setIsOpen(!isOpen)
    };
    return (
        <Box p={5} bg={"defaultDark"} h={"100%"}>
            <Text fontSize={22} fontWeight={"semibold"} color={"defaultWhite"}>Ledger</Text>
            <Box className="scrollBar" maxH={"90%"} overflowY={"scroll"}>
                <Flex py={2} align={"end"} justify={"space-between"}>
                    <HStack w={"90%"} justify={"flex-start"} color={"white"} align={"end"}>
                        <FormControl w={"25%"}>
                            <FormLabel fontSize={13}>Start Date</FormLabel>
                            <Input size={"sm"} bg={"white"} color={"black"} fontWeight={"semibold"} type={"date"} value={inputValue?.startDate} borderColor={"gray.400"} onChange={(e) => inputHandler("startDate", e.target.value)} />
                        </FormControl>
                        <FormControl w={"25%"}>
                            <FormLabel fontSize={13}>End Date</FormLabel>
                            <Input size={"sm"} bg={"white"} color={"black"} fontWeight={"semibold"} type={"date"} value={inputValue?.endDate} borderColor={"gray.400"} onChange={(e) => inputHandler("endDate", e.target.value)} />
                        </FormControl>
                        <FormControl w={"25%"}>
                            <FormLabel fontSize={13}>Payment Mode</FormLabel>
                            <Select size={"sm"} bg={"white"} color={"black"} fontWeight={"semibold"} value={inputValue?.type} borderColor={"gray.400"} onChange={(e) => inputHandler("type", e.target.value)}>
                                <option style={{ color: "black" }} value={"All"}>All</option>
                                <option style={{ color: "black" }} value={"Bank"}>Bank</option>
                                <option style={{ color: "black" }} value={"UPI"}>UPI</option>
                            </Select>
                        </FormControl >
                        <Button size={"sm"} colorScheme="blue" onClick={getFilter}>Filter</Button>
                    </HStack>
                    <Tooltip label="Download Excel" placement="top">
                        <DownloadExcel button={<RiFileExcel2Fill />} data={excelData} name={"Ledger Report"} />
                    </Tooltip>
                </Flex>
                <TableContainer mt={5}>
                    <Table size='sm' variant={"simple"}>
                        <Thead>
                            <Tr border={"1px solid"} borderColor={"whiteAlpha.500"}>
                                <Th color={"#e3e3e3"}>Date</Th>
                                <Th color={"#e3e3e3"}>Amount</Th>
                                <Th color={"#e3e3e3"}>Contact</Th>
                                {/* <Th color={"#e3e3e3"}>Closing Balance</Th> */}
                                <Th color={"#e3e3e3"}>Source</Th>
                                <Th color={"#e3e3e3"}>Reference Id</Th>
                                <Th color={"#e3e3e3"}>Via</Th>
                                <Th color={"#e3e3e3"}>UTR</Th>
                                <Th color={"#e3e3e3"}>Status</Th>
                                <Th color={"#e3e3e3"}>Detail</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {map(transactions, tran => (
                                <Tr color={"whiteAlpha.800"} borderColor={"whiteAlpha.500"}>
                                    <Td>{dayjs(tran.date).format("DD-MMM-YYYY hh:mm A")}</Td>
                                    <Td><Flex align={"center"} fontWeight={"semibold"} gap={1}><MdCurrencyRupee />{tran.amount}</Flex></Td>
                                    <Td fontWeight={"semibold"}>{tran.contact.name}</Td>
                                    {/* <Td>{tran.closingBalance}</Td> */}
                                    <Td>{tran.type}</Td>
                                    <Td>{tran.referenceId}</Td>
                                    <Td>{tran.via}</Td>
                                    <Td>{tran.transId}</Td>
                                    <Td>{tran.status}</Td>
                                    <Td> <IconButton
                                        size={"xs"}
                                        // colorScheme="blue"
                                        aria-label="Show details"
                                        icon={<ViewIcon />}
                                        onClick={() => handleShowDetails(tran)} // Your click handler
                                    />
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    {selectedItem && (
                        <DetailModal
                            item={selectedItem}
                            isOpen={isOpen}
                            onClose={() => {
                                setSelectedItem(null);
                                setIsOpen(false)
                            }}
                        />
                    )}
                </TableContainer>
            </Box>
        </Box >
    )
}